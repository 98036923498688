import styles from './FormFieldLabel.module.scss'

interface FormFieldLabelProps {
  label: string
}

const FormFieldLabel = ({ label }: FormFieldLabelProps) => {
  return <p className={styles.signupField}>{label}</p>
}

export default FormFieldLabel

import cn from 'classnames'
import Image from 'next/image'
import Link from 'next/link'

import LogoDarkSVG from '../../assets/dark_bg_logo.svg'
import LogoLightSVG from '../../assets/light_bg_logo.svg'

export interface LogoProps {
  /**
   * mode for dark and light
   */
  mode?: 'dark' | 'light'
  className?: string
}

export const Logo: React.FC<LogoProps> = ({ mode = 'dark', className }) => {
  return (
    <div className={cn('w-[200px] h-[35px] cursor-pointer flex items-center', `${className}`)}>
      <Link href="/" passHref={true}>
        <Image src={mode === 'dark' ? LogoDarkSVG : LogoLightSVG} width={200} height={35} alt="" />
      </Link>
    </div>
  )
}

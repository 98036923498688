import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'
import cn from 'classnames'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

export interface CheckBoxProps {
  label: string
  selected: boolean
  indeterminate: boolean
  disabled?: boolean
  onChanged: (checked: boolean, prop?: string) => void
  value?: string
  className?: string
  labelClassName?: string
  isCheckShow?: boolean
}

/**
 * Primary UI component for user interaction
 */
export const CheckBox: React.FC<CheckBoxProps> = ({
  label,
  selected,
  indeterminate,
  disabled,
  value,
  onChanged,
  className = '',
  labelClassName = '',
  isCheckShow = true,
  ...props
}) => {
  const capitalizedTitle = label
  const router = useRouter()
  const [isQuery, SetisQuery] = useState<string | undefined>('')
  useEffect(() => {
    const query = window.location.href.split('=')[2]
    SetisQuery(query)
  }, [router])
  return (
    <div
      className={cn(`flex items-start cursor-pointer group ${!isCheckShow && 'w-full h-[45px]'}`, className)}
      onClick={() => {
        if (disabled) {
          return
        }
        onChanged(!selected, value)
      }}
      {...props}
    >
      {isCheckShow && (
        <div className="rounded mr-[5px] w-5 h-5 flex-shrink-0">
          {selected && indeterminate && <CheckBoxIcon sx={{ fontSize: 16 }} />}
          {isQuery && !selected && !indeterminate && <CheckBoxOutlineBlankIcon sx={{ fontSize: 16 }} />}
          {isQuery && !selected && indeterminate && <IndeterminateCheckBoxIcon sx={{ fontSize: 16 }} />}
          {!selected && !isQuery && <CheckBoxOutlineBlankIcon sx={{ fontSize: 16 }} />}
        </div>
      )}
      {labelClassName === '' ? (
        <label
          className={cn(
            `${
              isCheckShow
                ? 'contents font-primary font-medium text-[15px] text-darkgray text-left'
                : 'font-secondary font-bold text-[14px] text-white m-auto text-center'
            } flex-shrink-0 cursor-pointer break-words w-full`,
          )}
        >
          {capitalizedTitle}
        </label>
      ) : (
        <label className={cn('', labelClassName)}>{capitalizedTitle}</label>
      )}
    </div>
  )
}

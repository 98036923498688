import cn from 'classnames'
import React, { HTMLInputTypeAttribute } from 'react'

import styles from './FormFieldInput.module.css'

interface FormFieldInputProps {
  setref?: null | any
  id: string
  name?: string
  type: HTMLInputTypeAttribute
  value: string
  required?: boolean
  className?: string
  errorMessage?: string
  tooltip?: string
  onChange: (title: string) => void
  disabled?: boolean
}

export const FormFieldInput: React.FC<FormFieldInputProps> = ({
  setref = null,
  id,
  name = '',
  type = '',
  value = '',
  className = '',
  required = false,
  errorMessage = '',
  tooltip = '',
  onChange,
  disabled = false,
}) => {
  return (
    <div className="flex flex-col relative">
      <input
        id={id}
        ref={setref}
        name={name}
        type={type}
        className={cn(
          'pl-[0.25rem] w-full',
          errorMessage === '' ? styles.rectangle : styles.rectangleError,
          disabled ? 'text-[gray]/60' : '',
          className,
        )}
        required={required}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
      />
      {errorMessage && <p className={cn('absolute top-[45px]', styles.error)}>{errorMessage}</p>}
      {!errorMessage && tooltip && (
        <p className="absolute top-[50px] text-shade font-secondary text-[14px]">{tooltip}</p>
      )}
    </div>
  )
}

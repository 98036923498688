import React from 'react'
import Modal from 'react-modal'

import { NewsletterForm } from './NewsletterForm/NewsletterForm'

interface NewsletterModalProps {
  isOpen: boolean
  onClose: () => void
}

const newsletterModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: '580px',
    marginRight: '-50%',
    padding: '0px',
    transform: 'translate(-50%, -50%)',
    borderWidth: '0px',
    boxShadow: '0 0 2px 2px rgba(180, 183, 197, 0.9)',
  },
  overlay: {
    zIndex: 1000,
  },
}

export const NewsletterModal: React.FC<NewsletterModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal
      id="newsletter-modal"
      isOpen={isOpen}
      onRequestClose={onClose}
      style={newsletterModalStyles}
      ariaHideApp={false}
    >
      <NewsletterForm onClose={onClose} />
    </Modal>
  )
}

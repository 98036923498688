import { Hidden } from '@mui/material'
import IonIcon from '@reacticons/ionicons'
import cn from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import XTwitterLogo from 'src/assets/X-Twitter.svg'
import { Logo } from 'src/components/global/Logo'
import { NewsletterButton } from 'src/components/Newsletter/NewsletterButton'
import * as enVariables from 'src/config/config'

import styles from './Footer.module.css'

export interface FOOTER_MENU_ITEM {
  title: string
  to: string
  type: string
}

export interface FOOTER_MENU {
  title: string
  items: FOOTER_MENU_ITEM[]
}

export const Footer: React.FC = () => {
  // const INDUSTRY_ANALYSIS: FOOTER_MENU_ITEM[] = [
  //   // { title: 'Insights & Articles', to: '/insights', type: '' },
  //   // { title: 'Regional Analysis', to: '/graveyard-consolidations', type: '' },
  //   // { title: 'Graveyard Consolidations', to: '/graveyard-consolidations', type: '' },
  // ]

  const EVENTS: FOOTER_MENU_ITEM[] = [
    { title: 'Upcoming Events', to: '/events', type: '' },
    { title: 'Submit an Event', to: `mailto:${enVariables.CONTACT_URL}?subject=Submit an event`, type: '' },
  ]

  const ABOUT_US: FOOTER_MENU_ITEM[] = [
    { title: 'Who We Are', to: '/about', type: 'who_we_are' },
    { title: 'Advisory Board', to: '/about', type: 'advisory_board' },
    { title: 'Contact Us', to: '/about', type: 'contact_us' },
  ]

  const RESOURCES: FOOTER_MENU_ITEM[] = [
    { title: 'For Vendors', to: '/requestvendor', type: '' },
    { title: 'For Law Firms', to: '/requestvendor', type: '' },
    { title: 'For Legal Departments', to: '/requestvendor', type: '' },
  ]

  const menu_footer: FOOTER_MENU[] = [
    // {
    //   title: 'INDUSTRY ANALYSIS',
    //   items: INDUSTRY_ANALYSIS,
    // },
    {
      title: 'Events',
      items: EVENTS,
    },
    {
      title: 'About Us',
      items: ABOUT_US,
    },
    {
      title: 'Resources',
      items: RESOURCES,
    },
  ]

  return (
    <div className={cn('w-full flex flex-row px-5 large-lg:px-0 justify-center bg-navy')}>
      <Hidden mdDown>
        <div className="!max-w-[1100px] w-full flex flex-col py-10 justify-between">
          <div className="grid py-4 gap-1 sm:grid-cols-4 lg:grid-cols-4">
            <Logo className="w-[11.2rem] h-[1.875rem] sm:col-span-4 lg:col-span-1" />
            {menu_footer.map((menu: FOOTER_MENU, idx: number) => {
              return (
                <div className="flex flex-col pl-[3.125rem]" key={idx}>
                  <span className={styles.homeFooterMenu}>{menu.title.toUpperCase()}</span>
                  {menu.items.map((item: FOOTER_MENU_ITEM, subIdx: number) => {
                    const url = item.type === '' ? item.to : `${item.to}?type=${item.type}`
                    return (
                      <span className={cn(styles.homeFooterMenuItem, 'cursor-pointer')} key={subIdx}>
                        {item.title === 'Legaltech Jobs' ? (
                          <Link href={url} passHref target="_blank" rel="noopener noreferrer">
                            Legal<span className="text-turquoise">tech</span>Jobs
                          </Link>
                        ) : (
                          <Link href={url} key={subIdx}>
                            {item.title}
                          </Link>
                        )}
                      </span>
                    )
                  }) || []}
                </div>
              )
            })}
          </div>
          <div className="w-full h-px bg-desertStorm opacity-20" />
          <div className="py-4 grid gap-4 sm:flex sm:justify-between">
            <div className="flex items-center">
              <span className={styles.connectWithLegalte}>CONNECT WITH LEGALTECH HUB</span>
              <a href="https://twitter.com/LegalTechHub1" target="_blank" className="flex items-center">
                {/* <IonIcon className="text-white cursor-pointer" name="logo-twitter" /> */}
                <Image src={XTwitterLogo} alt="X-Twitter" className="cursor-pointer" width={14} height={14} />
              </a>
              <a href="https://www.linkedin.com/company/legal-tech-hub/" target="_blank" className="flex items-center">
                <IonIcon className="text-white ml-[19px] cursor-pointer" name="logo-linkedin" />
              </a>
            </div>
            <NewsletterButton />
          </div>
          <div className="w-full h-px bg-desertStorm opacity-20" />
          <div className="grid py-4 gap-4 md:flex">
            <span className={cn(styles.homeCompanyInfo, 'opacity-50')}>
              &#169; {new Date().getFullYear()} Legaltech Hub All rights reserved.
            </span>
            <span className={cn(styles.homeCompanyInfo, 'opacity-70 cursor-pointer')}>
              <Link href="/privacy-policy">Privacy Policy</Link>
            </span>
            <span className={cn(styles.homeCompanyInfo, 'opacity-70 cursor-pointer')}>
              <Link href="/terms-conditions">Terms & Conditions</Link>
            </span>
            <span className={cn(styles.homeCompanyInfo, 'opacity-70 cursor-pointer')}>
              <Link href="/cookie-policy">Cookie Policy</Link>
            </span>
          </div>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className="!max-w-[1100px] w-full flex flex-col py-10 justify-between">
          <Logo className="w-[11.2rem] h-[1.875rem] sm:col-span-4 lg:col-span-1" />
          <div className="py-4 flex flex-wrap">
            {menu_footer.map((menu: FOOTER_MENU, idx: number) => {
              return (
                <div className="flex flex-col w-full" key={idx}>
                  <span className={styles.homeFooterMenu}>{menu.title.toUpperCase()}</span>
                  {menu.items.map((item: FOOTER_MENU_ITEM, subIdx: number) => {
                    let type = item.type
                    if (item.title === 'Advisory Board' || item.title === 'Contact Us') {
                      type = `m-${type}`
                    }
                    const url = type === '' ? item.to : `${item.to}?type=${type}`
                    return (
                      <span className={cn(styles.homeFooterMenuItem, 'cursor-pointer')} key={subIdx}>
                        {item.title === 'Legaltech Jobs' ? (
                          <Link href={url} passHref target="_blank" rel="noopener noreferrer">
                            Legal<span className="text-turquoise">tech</span>Jobs
                          </Link>
                        ) : (
                          <Link href={url} key={subIdx}>
                            {item.title}
                          </Link>
                        )}
                      </span>
                    )
                  }) || []}
                </div>
              )
            })}
          </div>
          <div className="w-full h-px bg-desertStorm opacity-20" />
          <div className="py-4 grid gap-4 sm:flex sm:justify-between">
            <div className="flex items-center">
              <span className={styles.connectWithLegalte}>CONNECT WITH LEGALTECH HUB</span>
              <a href="https://twitter.com/LegalTechHub1" target="_blank">
                {/* <IonIcon className="text-white cursor-pointer" name="logo-twitter" /> */}
                <Image src={XTwitterLogo} alt="X-Twitter" className="cursor-pointer" width={16} height={16} />
              </a>
              <a href="https://www.linkedin.com/company/legal-tech-hub/" target="_blank">
                <IonIcon className="text-white ml-[19px] cursor-pointer" name="logo-linkedin" />
              </a>
            </div>
            <NewsletterButton />
          </div>
          <div className="w-full h-px bg-desertStorm opacity-20" />
          <div className="grid py-4 gap-4 md:flex">
            <span className={cn(styles.homeCompanyInfo, 'opacity-50')}>
              &#169; {new Date().getFullYear()} Legaltech Hub All rights reserved.
            </span>
            <span className={cn(styles.homeCompanyInfo, 'opacity-70 cursor-pointer')}>
              <Link href="/privacy-policy">Privacy Policy</Link>
            </span>
            <span className={cn(styles.homeCompanyInfo, 'opacity-70 cursor-pointer')}>
              <Link href="/terms-conditions">Terms & Conditions</Link>
            </span>
            <span className={cn(styles.homeCompanyInfo, 'opacity-70 cursor-pointer')}>
              <Link href="/cookie-policy">Cookie Policy</Link>
            </span>
          </div>
        </div>
      </Hidden>
    </div>
  )
}
